import request from '@/utils/request'
import { url } from '@/config'

export const list = (data) => {
  return request({
    url: url + '/car/carTraceOld/list?pageNum='+data.pageNum+'&pageSize='+data.pageSize+'&videoId='+data.videoId+'&pictureId='+data.search.pictureId+'&carId='+data.search.carId+'&globalId='+data.search.globalId,
    method: 'get',
    meta: {
      isSerialize: true
    }
  })
}

export const bindStatus = (data) => {
    return request({
      url: url + '/car/carTraceBindRecordOld/bindStatus?carTraceIds='+data,
      method: 'get',
      meta: {
        isSerialize: true
      }
    })
  }
export const unBindStatus = (data) => {
    return request({
      url: url + '/car/carTraceBindRecordOld/unBindStatus?carTraceIds='+data,
      method: 'get',
      meta: {
        isSerialize: true
      }
    })
  }
