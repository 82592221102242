
import request from '@/utils/request'
import { url } from '@/config'
export const list = (data) => {
  return request({
    url: url + '/base/role/list',
    method: 'get',
    params: data
  })
}
export const del = (ids) => request({
  url: url + '/base/role/delete?ids=' + ids,
  method: 'delete'
})
export const add = (data) => request({
  url: url + '/base/role/add',
  method: 'post',
  params: data
})
export const detail = (ids) => request({
  url: url + '/base/role/getItem?id=' + ids,
  method: 'get',
})

export const update = (data) => {
  return request({
    url: url + '/base/role/update',
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    params: data
  })
}

export const bind = (id, data) => request({
  url: url + '/urp/user/' + id +'/bind-role',
  method: 'post',
  data: data
})