import request from '@/utils/request'
import { url } from '@/config'

export const list = (data) => {
    return request({
      url: url + '/car/carTraceBase/list?pageNum='+data.pageNum+'&pageSize='+data.pageSize+'&videoId='+data.videoId+'&pictureId='+data.search.pictureId+'&carId='+data.search.carId+'&globalId='+data.search.globalId,
      //url: url + '/carTrace/list',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
}

export const getDetailList = (data) => {
    return request({
      url: url + '/car/carTraceBase/getItem?id='+data,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
}



export const saveDrawList = (data) => {
    return request({
      url: url + '/car/carTraceBase/draw',
      method: 'post',
      parames:data,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
}
  