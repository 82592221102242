
import request from '@/utils/request'
import { url } from '@/config'
export function login (username, password) {
  return request({
    url: url + '/auth/sign-in',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      username,
      password
    }
  })
}


export const getInfo = (userName) => request({
  url: url + '/base/user/getItemByUserName?userName='+userName,
  method: 'get',
})

export function getCodeImg () {
  return request({
    url: url + '/auth/code',
    method: 'get'
  })
}

export function logout () {
  return request({
    url: url + '/auth/sign-out',
    method: 'post'
  })
}
