import request from '@/utils/request'
import { url } from '@/config'

export const list = (data) => {
  let carIDs = data.actualCarIds?data.actualCarIds:''
  return request({
    url: url + '/car/carTraceBindRecord/countData?pageNum='+data.pageNum+'&pageSize='+data.pageSize+'&desc=createTime'+'&actualCarIds='+carIDs,
    method: 'get',
    meta: {
      isSerialize: true
    }
  })
}

export const detaillist = (data) => {

  return request({
    url: url + '/car/carTraceBindRecord/list',
    method: 'get',
    meta: {
      isSerialize: true
    },
    params:data
  })
}

