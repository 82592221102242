export default {
    translate: false,
    searchLabelWidth: 100,
    labelWidth: 110,
    selection: true,
    tip: false,
    index: true,
    align: 'center',
    menu: false,
    menuBtn:false,
    headerAlign: 'center',
    border: true,
    stripe: true,
    addBtn:false,
    delBtn:false,
    editBtn:false,
    updateBtn:false,
    viewBtn:false,
    cancelBtn: false,
    dialogCloseBtn:true,
    page:true,
    column: [{
      label: "用户名",
      prop: "userName",
    },
    {
      label: "标记数",
      prop: "totalQty",
    },
    {
      label: "绑定数",
      prop: "bindQty",
      slot:true
    },
    {
      label: "解绑数",
      prop: "unBindQty",
      slot:true
    },
    {
      label: "标记后被修改数",
      prop: "updateQty",
    },
  ],
  }