import { login, getInfo, logout } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import store from 'store2'

const user = {
  state: {
    token: getToken(),
    user: store.get('user') || {},
    roles: store.get('roles') || [],
    permission: store.get('permission') || [],
    // 第一次加载菜单时用到
    loadMenus: false
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_PERMISSION: (state, permission) => {
      state.permission = permission
      store.set('permission', permission)
    },
    SET_USER: (state, user) => {
      state.user = user
      store.set('user', user)
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
      store.set('roles', roles)
    },
    SET_LOAD_MENUS: (state, loadMenus) => {
      state.loadMenus = loadMenus
    }
  },

  actions: {
    // 登录
    Login ({ commit, dispatch }, userInfo) {
      console.log(userInfo)
      //debugger
      return new Promise((resolve, reject) => {
        login(userInfo.username, userInfo.password).then(res => {
          if(!res){
            commit('SET_LOAD_MENUS', true)
           return dispatch('GetInfo',userInfo)
          }else{
            reject({errCode:res.detail})
          }
          
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit },userInfo) {
      return new Promise((resolve, reject) => {
        getInfo(userInfo.username).then(res => {
          let list = res.data.roleList;
          let permission = [], roles = [];
          if(!list){
            reject({errCode:'NO_ROLE'})
          }
          list.forEach(ele => {
            (ele.permissionList || []).forEach(item => {
              permission.push(item.code)
            })
          })
          let id = res.data.id
          setToken(id)
          let userdata = res.data
          userdata.password = userInfo.password
          commit('SET_TOKEN', id)
          commit('SET_USER', res.data)
          commit('SET_ROLES', roles)
          commit('SET_PERMISSION', permission)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    LogOut ({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USER', {})
          commit('SET_ROLES', [])
          commit('SET_PERMISSION', [])
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateLoadMenus ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_MENUS', false)
      })
    }
  }
}

export default user
