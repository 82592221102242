
import request from '@/utils/request'
import { url } from '@/config'
export const list = (data) => {
  return request({
    url: url + '/base/user/list',
    method: 'get',
    params: data
  })
}
export const del = (ids) => request({
  url: url + '/users/delete/' + ids,
  method: 'delete'
})
export const add = (data) => request({
  url: url + '/auth/sign-up',
  method: 'post',
  headers: {
    'Content-Type': 'application/json',
  },
  data: data
})

export const detail = (ids) => request({
  url: url + '/base/user/getItemByUserName?userName=' + ids,
  method: 'get',
})

export const update = (data) => request({
  url: url + '/base/user/update',
  method: 'put',
  headers: {
    'Content-Type': 'application/json',
  },
  params: data
})

export const updatePassword = (data) => request({
  url: url + '/base/user/updatePassword',
  method: 'post',
  params: data
})

export const resetPassword = (data) => request({
  url: url + '/base/user/resetPassword',
  method: 'post',
  params: data
})

