import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../layout/index'
Vue.use(Router)
export const constantRouterMap = [
  {
    path: '/',
    component: Layout,
    redirect: '/car',
    meta: { title: '车辆管理', noCache: true, icon: "app", permission: 'M_CARTRACE' },
    children: [
      {
        path: 'car',
        meta: { title: '车辆识别强化学习标注', noCache: true, icon: 'app', permission: 'M_CARTRACE_CAR' },
        name: "车辆识别强化学习标注",
        component: (resolve) => require(['@/views/carTrace/carTrace.vue'], resolve),
        hidden: false
      }, 
      // {
      //   path: 'image-rect',
      //   meta: { title: '图片标框', noCache: true, icon: 'app', permission: 'M_CARTRACE_CAR' },
      //   name: "图片标框",
      //   component: (resolve) => require(['@/views/imgRect/imgRect.vue'], resolve),
      //   hidden: true
      // }, 
      {
        path: 'image-rect-regular',
        meta: { title: '图片标框New', noCache: true, icon: 'app', permission: 'M_CARTRACE_CAR' },
        name: "图片标框New",
        component: (resolve) => require(['@/views/imgRect/imgRectRegular.vue'], resolve),
        hidden: false
      },
      {
        path: 'image-rect-regular-check',
        meta: { title: '机器标注', noCache: true, icon: 'app', permission: 'M_CARTRACE_CAR' },
        name: "机器标注",
        component: (resolve) => require(['@/views/imgRect/imgRectRegularCheck.vue'], resolve),
        hidden: true
      },
      {
        path: 'carBindRecord',
        component: (resolve) => require(['@/views/carTrace/carBindRecord.vue'], resolve),
        name: '车辆绑定记录',
        meta: { title: '车辆绑定记录', icon: 'index', noCache: true, permission: 'M_CARTRACE_BIND' }
      }, {
        path: 'carTrace',
        component: (resolve) => require(['@/views/carTrace/carTraceOld.vue'], resolve),
        name: '未标注车辆识别',
        meta: { title: '未标注车辆识别', icon: 'index', noCache: true, permission: 'M_CARTRACE_OLD' }
      },
      {
        path: 'countData',
        component: (resolve) => require(['@/views/carTrace/countData.vue'], resolve),
        name: '用户标注统计',
        meta: { title: '用户标注统计', icon: 'index', noCache: true, permission: 'M_COUNT_DATA' }
      },
    ]
  },
  {
    path: '/system',
    meta: { title: '系统设置', noCache: true, icon: 'app', permission: 'M_SYS' },
    // component: (resolve) => require(['@/views/template/index.vue'], resolve),
    redirect: 'system/user',
    component: Layout,
    alwaysShow: true,
    hidden: false,
    children: [
      {
        path: 'user',
        meta: { title: '用户管理', noCache: true, icon: 'app', permission: 'M_SYS_USER' },
        name: "用户管理",
        component: (resolve) => require(['@/views/sys/user.vue'], resolve),
        hidden: false
      },
      {
        path: 'role',
        meta: { title: '角色管理', noCache: true, icon: 'app', permission: 'M_SYS_ROLE' },
        name: "角色管理",
        component: (resolve) => require(['@/views/sys/role.vue'], resolve),
        hidden: false
      },
      {
        path: 'permission',
        meta: { title: '权限管理', noCache: true, icon: 'app', permission: 'M_SYS_PERMISSION' },
        name: "权限管理",
        component: (resolve) => require(['@/views/sys/permission.vue'], resolve),
        hidden: false
      },
      // {
      //   path: 'params',
      //   meta: { title: '参数管理', noCache: true, icon: 'app', permission: 'M_SYS_PARAM' },
      //   name: "参数管理",
      //   component: (resolve) => require(['@/views/system/param.vue'], resolve),
      //   hidden: false
      // },
    ]
  },
  {
    path: '/login',
    meta: { title: '登录', noCache: true },
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/features/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/features/401'], resolve),
    hidden: true
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: (resolve) => require(['@/views/features/redirect'], resolve)
      }
    ]
  }
]

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  // mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
