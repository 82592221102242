import request from '@/utils/request'
import { url } from '@/config'


export const list = (data) => {
  return request({
    url: url + '/car/carTrace/list?pageNum='+data.pageNum+'&pageSize='+data.pageSize+'&videoId='+data.videoId+'&pictureId='+data.search.pictureId+'&carId='+data.search.carId+'&globalId='+data.search.globalId,
    //url: url + '/carTrace/list',
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const bindStatus = (data) => {
    return request({
      url: url + '/car/carTraceBindRecord/bindStatus?carTraceIds='+data,
      method: 'get',
      meta: {
        isSerialize: true
      }
    })
  }
export const unBindStatus = (data) => {
    return request({
      url: url + '/car/carTraceBindRecord/unBindStatus?carTraceIds='+data,
      method: 'get',
      meta: {
        isSerialize: true
      }
    })
  }
