export default {
    translate: false,
    searchLabelWidth: 100,
    labelWidth: 110,
    selection: true,
    tip: false,
    index: true,
    align: 'center',
    menu: true,
    menuBtn:true,
    headerAlign: 'center',
    border: true,
    stripe: true,
    addBtn:false,
    delBtn:false,
    editBtn:false,
    updateBtn:false,
    viewBtn:false,
    cancelBtn: false,
    dialogCloseBtn:true,
    page:true,
    column: [{
      label: "操作动作",
      prop: "bindStatus",
      slot: true,
      html:true,
      formatter:(val,value,label)=>{
        if(value){
            return `<span style="color:#999">解绑</span>`
        }else{
            return `<span style="color:#409EFF">绑定</span>`
        }    
     }
    },
    {
      label: "操作时间",
      prop: "createTime",
      width:220,
      slot: true,
    },
    {
        label: "操作图片",
        prop: "tblCarTraceList",
        // hide:true,
        // showColumn:false,
        slot: true,
        span:24,
        html:true,
        formatter:(val,value,label)=>{
          let str = '' 
          for(let i=0;i<value.length;i++){
            str=str + value[i].videoName+':'+value[i].url.split('/')[value[i].url.split('/').length-1]+'<br/>'
          }

          return str
       }
    },
    {
      label: "车辆Id",
      prop: "actualCarIds",
      search: true,
      hide:true,
      showColumn:false
    },
    //
  ],
  }