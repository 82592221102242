
import request from '@/utils/request'
import { url } from '@/config'
export const list = (data) => {
  return request({
    url: url + '/base/permission/list',
    method: 'get',
    params: data
  })
}
export const del = (ids) => request({
  url: url + '/base/permission/delete?ids=' + ids,
  method: 'delete'
})
export const add = (data) => request({
  url: url + '/base/permission/add',
  method: 'post',
  headers: {
    'Content-Type': 'application/json',
  },
  params: data
})
export const detail = (ids) => request({
  url: url + '/base/permission/detail/' + ids,
  method: 'post'
})
export const update = (data) => {
  return request({
    url: url + '/base/permission/update',
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    params: data
  })
}

export const bind = (id, data) => request({
  url: url + '/urp/role/' + id + '/bind-permission',
  method: 'post',
  data: data
})



