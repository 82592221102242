import { url } from '@/config'
export default {
  dialogWidth: '30%',
  menuWidth: 180,
  translate: false,
  searchLabelWidth: 100,
  labelWidth: 120,
  selection: true,
  tip: false,
  index: true,
  height: 'auto',
  align: 'center',
  headerAlign: 'center',
  border: true,
  stripe: true,
  translate: false,
  calcHeight: 60,
  column: [{
    label: '名称',
    prop: 'username',
    span: 24,
    search: true,
    rules: [{
      required: true,
      message: "请输入名称",
      trigger: "blur"
    }]
  }, {
    label: '密码',
    prop: 'password',
    span: 24,
    type: 'password',
    hide: true,
    editDisplay: false,
    rules: [{
      required: true,
      message: "请输入密码",
      trigger: "blur"
    }]
  }, {
    label: '手机号',
    prop: 'phone',
    span: 24,
    rules: [{
      required: true,
      message: "请输入手机号",
      trigger: "blur"
    }]
  },
  // {
  //   label: '电话',
  //   prop: 'phone',
  //   span: 24,
  //   search: true,
  //   rules: [{
  //     required: true,
  //     message: "请输入电话",
  //     trigger: "blur"
  //   }]
  // }, 
  // {
  //   label: '组织',
  //   prop: 'orgId',
  //   span: 24,
  //   search: true,
  //   dataType: 'string',
  //   type: 'select',
  //   dicUrl: url + '/org-manage/list?size=100',
  //   dicFormatter: (data) => {
  //     return data.records
  //   },
  //   props: {
  //     label: 'name',
  //     value: 'id'
  //   },
  //   rules: [{
  //     required: true,
  //     message: "请选择组织",
  //     trigger: "blur"
  //   }]
  // }, 
  {
    label: '创建时间',
    prop: 'createTime',
    width: 160,
    display: false
  }, 
  // {
  //   label: '创建人',
  //   prop: 'createUser',
  //   display: false
  // }
]
}