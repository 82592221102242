<template>
  <div class="navbar">
    <hamburger id="hamburger-container"
               :is-active="sidebar.opened"
               class="hamburger-container"
               @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container"
                class="breadcrumb-container" />

    <div class="right-menu">
      <template v-if="device!=='mobile'">
        <search id="header-search"
                class="right-menu-item" />

        <el-tooltip content="全屏缩放"
                    effect="dark"
                    placement="bottom">
          <screenfull id="screenfull"
                      class="right-menu-item hover-effect" />
        </el-tooltip>

        <el-tooltip content="布局设置"
                    effect="dark"
                    placement="bottom">
          <size-select id="size-select"
                       class="right-menu-item hover-effect" />
        </el-tooltip>

      </template>

      <el-dropdown class="avatar-container right-menu-item hover-effect"
                   trigger="click">
        <div class="avatar-wrapper"
             style="height:100%">
          <img src="/img/logo.png"
               class="user-avatar">
          <i class="el-icon-caret-bottom" />
          <p class="m0 uname"
             style="display:inline-block;line-height:50px;">{{user.username}}</p>
        </div>
        <el-dropdown-menu slot="dropdown">
          <!-- <span style="display:block;"
                @click="show = true">
            <el-dropdown-item>
              布局设置
            </el-dropdown-item>
          </span> -->
          <span style="display:block;"
                @click="dialogVisible = true">
            <el-dropdown-item>
              修改密码
            </el-dropdown-item>
          </span>
          <span style="display:block;"
                @click="open">
            <el-dropdown-item>
              退出登录
            </el-dropdown-item>
          </span>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog class="avue-dialog"
               append-to-body
               show-close
               close-on-press-escape
               close-on-click-modal
               title="修改密码"
               :visible.sync="dialogVisible"
               width="30%">
      <el-form :model="userForm"
               status-icon
               :rules="rules"
               ref="user"
               size="small"
               label-width="100px">
        <el-form-item label="旧密码"
                      prop="oldpassword">
          <el-input type="password"
                    show-password
                    v-model="userForm.oldpassword"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码"
                      prop="newspassword">
          <el-input type="password"
                    show-password
                    v-model="userForm.newspassword"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="handlePasswd">提交</el-button>
          <!-- <el-button type="danger"
                     @click="logout">退出</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'
import { updatePassword } from '@/api/sys/user'
import { validPass } from '@/utils/utils'

export default {
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search
  },
  data () {
    return {
      dialogVisible: false,
      userForm: {
        oldpassword: '',
        newspassword: ''
      },
      rules: {
        oldpassword: [
          { required: true, message: '旧密码不能为空', trigger: 'blur' },
        ],
        newspassword: [
          { required: true, message: '新密码不能为空', trigger: 'blur' },
          { validator: validPass, trigger: 'change' }
        ]
      },
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'device',
      'user',
      'baseApi'
    ]),
    show: {
      get () {
        return this.$store.state.settings.showSettings
      },
      set (val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    }
  },
  mounted () {
  },
  methods: {
    handlePasswd () {
      this.$refs.user.validate((valid) => {
        if (valid) {
          updatePassword({
            id: this.user.id,
            userName: this.user.username,
            oldPassWord: this.userForm.oldpassword,
            newPassWord: this.userForm.newspassword
          }).then(() => {
            this.userForm.oldpassword = '';
            this.userForm.newspassword = ''
            this.dialogVisible = false;
            this.$message.success('密码修改成功')
          })
        }
      });
    },
    toggleSideBar () {
      this.$store.dispatch('app/toggleSideBar')
    },
    open () {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return this.$store.dispatch('LogOut')
      }).then(() => {
        location.reload()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.uname {
  position: relative;
  top: -12px;
  bottom: 0;
  padding-left: 10px;
}
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
